














































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import {
  INQUIRIES_FOR_TALENT,
  ACCEPT_DECLINE
} from '@/store/inquiry/actions.ts'
import InquiryPageLayout from '@/views/layouts/InquiryPageLayout.vue'
import { InquiryDto } from '@/store/inquiry/types'
import { GenericRequest, SimpleServerResponse } from '@/store/types'
import Enums from '@/common/Enums'
import {
  ACCEPT, DECLINE, getAcceptDeclinePositiveFeedback,
  getCompanyInquiryConfirmationExplanationForStudent,
  getCompanyInquiryConfirmationHeadingForStudent
} from '@/common/inquiry'
import { SortingInfo } from '@/store/commonFormInput/types'
@Component({
  components: { InquiryPageLayout }
})
export default class CompanyInquiries extends Vue {
  @Action(INQUIRIES_FOR_TALENT)
  public getInquiries: (status: string) => Promise<InquiryDto[]>

  @Action(ACCEPT_DECLINE)
  public acceptOrDecline: (dto: GenericRequest) => Promise<SimpleServerResponse>

  private openInquiries: Array<InquiryDto> = [];
  private acceptedInquiries: Array<InquiryDto> = [];
  private declinedInquiries: Array<InquiryDto> = [];
  private isLoading = false
  private showError = false
  private modalHeading = ''
  private modalExplanation = ''

  created (): void {
    this.loadData()
  }

  get sortable (): SortingInfo[] {
    return [
      { key: 'inquiryModifiedAt', label: this.$i18n.t('inquiries.request-date').toString() },
      { key: 'jobAdTitle', label: this.$i18n.t('job-ad.title').toString() },
      { key: 'companyName', label: this.$i18n.t('inquiries.company').toString() }
    ]
  }

  showJobAd (selectedInquiry: InquiryDto): void {
    let interactionButtons: string[]
    switch (selectedInquiry.inquiryStatus) {
      case Enums.OPEN_INQUIRY_TO_TALENT: {
        interactionButtons = [ACCEPT, DECLINE]
        break
      }
      case Enums.INQUIRY_DENIED_BY_TALENT: {
        interactionButtons = [ACCEPT]
        break
      }
      case Enums.INQUIRY_ACCEPTED_BY_TALENT: {
        interactionButtons = [DECLINE]
        break
      }
      default: {
        interactionButtons = []
        break
      }
    }
    this.$router.push({
      name: 'inquiry-job-ad',
      params: {
        id: selectedInquiry.jobAdId,
        isApplication: 'false',
        inquiryId: selectedInquiry.inquiryId as string,
        options: JSON.stringify(interactionButtons)
      }
    })
  }

  openConfirmationModal (desiredAction: string): void {
    this.modalHeading = getCompanyInquiryConfirmationHeadingForStudent(desiredAction)
    this.modalExplanation = getCompanyInquiryConfirmationExplanationForStudent(desiredAction)
  }

  inquiryResponse (selectedInquiry: InquiryDto, desiredAction: string): void {
    if (selectedInquiry.inquiryId !== undefined) {
      const dto: GenericRequest = { query: [], params: undefined }
      if (desiredAction === ACCEPT) {
        dto.query.splice(0, 1, 'true')
      } else {
        dto.query.splice(0, 1, 'false')
      }
      dto.query[1] = selectedInquiry.inquiryId as string
      this.acceptOrDecline(dto).then(() => {
        switch (selectedInquiry.inquiryStatus) {
          case Enums.OPEN_INQUIRY_TO_TALENT: {
            this.openInquiries.splice(this.openInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
            break
          }
          case Enums.INQUIRY_ACCEPTED_BY_TALENT: {
            this.acceptedInquiries.splice(this.acceptedInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
            break
          }
          case Enums.INQUIRY_DENIED_BY_TALENT: {
            this.declinedInquiries.splice(this.declinedInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
            break
          }
        }
        const message = getAcceptDeclinePositiveFeedback(desiredAction)
        if (desiredAction === ACCEPT) {
          selectedInquiry.inquiryStatus = Enums.INQUIRY_ACCEPTED_BY_TALENT
          this.acceptedInquiries.push(selectedInquiry)
        } else {
          selectedInquiry.inquiryStatus = Enums.INQUIRY_DENIED_BY_TALENT
          this.declinedInquiries.push(selectedInquiry)
        }
        this.$root.$emit('alert', message, '', false)
      }).catch(() => {
        this.$root.$emit('alert', this.$i18n.t('warning.general-error-message').toString(), '', true)
      })
    }
  }

  private loadData (): void {
    this.isLoading = true
    Promise.all([
      this.getInquiries(Enums.OPEN_INQUIRY_TO_TALENT),
      this.getInquiries(Enums.INQUIRY_ACCEPTED_BY_TALENT),
      this.getInquiries(Enums.INQUIRY_DENIED_BY_TALENT)
    ]).then((data) => {
      this.openInquiries = data[0]
      this.acceptedInquiries = data[1]
      this.declinedInquiries = data[2]
    }).finally(() => {
      this.isLoading = false
    })
  }
}
