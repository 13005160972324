const COMPANY_SIZES = ['A', 'B', 'C', 'D', 'E', 'F']
const CV_TYPES = ['STUDY', 'APPRENTICESHIP', 'ABROAD', 'SCHOOL', 'JOB', 'PROJECT', 'INTERNSHIP', 'STUDENT_ASSISTANT', 'OTHER']
const EMPLOYMENT_STATES = ['SEARCH', 'EMPLOYED']

const JOB_AD_STATUS_INCOMPLETE = 'INCOMPLETE'
const JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED = 'COMPLETED_NOT_PUBLISHED'
const JOB_AD_STATUS_PUBLISHED = 'PUBLISHED'
const JOB_AD_STATUS = [JOB_AD_STATUS_INCOMPLETE, JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED, JOB_AD_STATUS_PUBLISHED]

const BASIC_CONDITION = 'BASIC_CONDITION'
const ACTIVITY_DESCRIPTION = 'ACTIVITY_DESCRIPTION'
const QUALIFICATION_REQUIREMENT = 'QUALIFICATION_REQUIREMENT'
const COMPETENCE_REQUIREMENT = 'COMPETENCE_REQUIREMENT'
const MATCHING = 'MATCHING'
const PUBLISH_JOB_AD = 'PUBLISH_JOB_AD'
const JOB_EDITOR_STEPS = [BASIC_CONDITION, ACTIVITY_DESCRIPTION, QUALIFICATION_REQUIREMENT, COMPETENCE_REQUIREMENT, MATCHING, PUBLISH_JOB_AD]

const INQUIRY_ACCEPTED_BY_COMPANY = 'INQUIRY_ACCEPTED_BY_COMPANY'
const INQUIRY_ACCEPTED_BY_TALENT = 'INQUIRY_ACCEPTED_BY_TALENT'
const INQUIRY_DENIED_BY_COMPANY = 'INQUIRY_DENIED_BY_COMPANY'
const INQUIRY_DENIED_BY_TALENT = 'INQUIRY_DENIED_BY_TALENT'
const OPEN_INQUIRY_TO_COMPANY = 'OPEN_INQUIRY_TO_COMPANY'
const OPEN_INQUIRY_TO_TALENT = 'OPEN_INQUIRY_TO_TALENT'
const NOT_ALLOWED = 'NOT_ALLOWED'

const LANGUAGES = [
  'AKAN',
  'AMHARIC',
  'ARABIC',
  'AZERBAIJANI',
  'ASSAMESE',
  'AWADHI',
  'BALOCHI',
  'BENGALI',
  'BHOJPURI',
  'BURMESE',
  'CEBUANO',
  'CHHATTISGARHI',
  'CHEWA',
  'CHITTAGONIAN',
  'DECCAN',
  'GERMAN',
  'DHUNDHARI',
  'ENGLISH',
  'FARSI',
  'FRENCH',
  'FULA',
  'GAN',
  'GREEK',
  'GUJARATI',
  'HAITIAN_CREOLE',
  'HAKKA',
  'HARYANVI',
  'HAUSA',
  'HILIGAYNON',
  'HINDI',
  'HMONG',
  'IGBO',
  'ILOCANO',
  'ISIXHOSA',
  'ISIZULU',
  'ITALIAN',
  'JAPANESE',
  'JAVANESE',
  'JIN',
  'KANNADA',
  'CANTONESE',
  'KAZAKH',
  'KHMER',
  'KINYARWANDA',
  'KIRUNDI',
  'KONKANI',
  'KOREAN',
  'KURDISH',
  'MADURESE',
  'MAGAHI',
  'MAITHILI',
  'MALAGASY',
  'MALAY',
  'MALAYALAM',
  'MANDARIN',
  'MARATHI',
  'MARWARI',
  'MIN_BEI',
  'MIN_DONG',
  'MIN_NAN',
  'MOSSI',
  'NEPALI',
  'DUTCH',
  'ODIA',
  'OROMO',
  'PUNJABI',
  'PASHTO',
  'POLISH',
  'PORTUGUESE',
  'QUECHUA',
  'ROMANIAN',
  'RUSSIAN',
  'SARAIKI',
  'SWEDISH',
  'SERBO_CROATIAN',
  'SHONA',
  'SINDHI',
  'SINHALA',
  'SOMALI',
  'SPANISH',
  'SYLHETI',
  'TAGALOG',
  'TAMIL',
  'TELUGU',
  'THAI',
  'CZECH',
  'TURKISH',
  'TURKMEN',
  'UYGHUR',
  'UKRAINIAN',
  'HUNGARIAN',
  'URDU',
  'UZBEK',
  'VIETNAMESE',
  'BELARUSIAN',
  'WU',
  'XIANG',
  'YORUBA',
  'ZHUANG'
]
const LANGUAGE_LEVELS = ['BASIC', 'GOOD', 'VERY_GOOD', 'LANGUAGE_PROFICIENT', 'MOTHER_TONGUE']
const RESPONSIBILITY_BUDGET = ['BUDGET_RESPONSIBILITY', 'NO_BUDGET_RESPONSIBILITY', 'NOT_SPECIFIED']
const RESPONSIBILITY_EMPLOYEES = ['NO_EMPLOYEE_RESPONSIBILITY', 'EMPLOYEE_RESPONSIBILITY_DISCIPLINARY', 'EMPLOYEE_RESPONSIBILITY_TECHNICAL', 'EMPLOYEE_RESPONSIBILITY_BOTH_DISC_TECH', 'NOT_SPECIFIED']
const TEAMWORK = ['IN_TEAM', 'NOT_IN_TEAM', 'NOT_SPECIFIED']
const SALUTATIONS = ['MR', 'MRS', 'EMPTY_DUMMY']
const SCHOOL_DEGREES = ['ALEVELS', 'ALTERNATIVE-QUALIFICATION', 'ADVANCED-TECHNICAL-CERTIFICATE', 'SECONDARY-SCHOOL-CERTIFICATE', 'SECONDARY-MODERN-SCHOOL-CERTIFICATE', 'OTHER']
const SECTORS = ['PRIVATE', 'PUBLIC']
const TARGET_PROFILE_TYPES = ['JOB_FAMILY', 'CROSS_COMPETENCES']
const TYPES = ['PRIVATE', 'PUBLIC']
const TITLES = ['EMPTY_DUMMY', 'PROF', 'DRDR', 'PROFDRDR', 'DR', 'PROFDR']
const USER_ROLES = ['ALUMNI', 'COMPANY']
const WORKING_HOURS = ['PART_TIME', 'FULL_TIME']

export default {
  COMPANY_SIZES,
  CV_TYPES,
  EMPLOYMENT_STATES,
  JOB_AD_STATUS,
  JOB_AD_STATUS_INCOMPLETE,
  JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED,
  JOB_AD_STATUS_PUBLISHED,
  LANGUAGES,
  LANGUAGE_LEVELS,
  RESPONSIBILITY_BUDGET,
  RESPONSIBILITY_EMPLOYEES,
  TEAMWORK,
  SALUTATIONS,
  SCHOOL_DEGREES,
  SECTORS,
  TARGET_PROFILE_TYPES,
  TYPES,
  TITLES,
  USER_ROLES,
  WORKING_HOURS,
  BASIC_CONDITION,
  ACTIVITY_DESCRIPTION,
  QUALIFICATION_REQUIREMENT,
  COMPETENCE_REQUIREMENT,
  MATCHING,
  PUBLISH_JOB_AD,
  JOB_EDITOR_STEPS,
  INQUIRY_ACCEPTED_BY_COMPANY,
  INQUIRY_ACCEPTED_BY_TALENT,
  INQUIRY_DENIED_BY_COMPANY,
  INQUIRY_DENIED_BY_TALENT,
  OPEN_INQUIRY_TO_COMPANY,
  OPEN_INQUIRY_TO_TALENT,
  NOT_ALLOWED
}
